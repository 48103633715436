import './style.scss'
import {harnessImage} from "../../assets/landingPageAssets";

export default function HarnessThePower() {

    return (
        <div className={'harnessThePower-section'}>
            <section className={'harness-heading'}>
                <h1>HARNESS THE POWER OF PERSONALISATION</h1>
            </section>
            <section className={'harness-image'}>
                <img src={harnessImage} alt={'harnessImage'}/>
            </section>
        </div>
    )
}