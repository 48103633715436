import './style.scss'
import BoostSectionDivCard from "../../component/boostSectionDivCard/BoostSectionDivCard";
import {galacticMission, graphChart, playGameBlue, playGameGreen, spinTheWheel} from "../../assets/landingPageAssets";



export default  function BoostYourBrand() {

    return(
        <div className={'boostYourBrand'}>
            <section className={'boost-heading'}>Boost your brands growth with customised games</section>
           <BoostSectionDivCard
               heading={'CUSTOMIZED HYPER CASUAL GAMES'}
               content={'Boost your brands marketing with customised hyper casual games'}
               image1={spinTheWheel}
               image2={galacticMission}
               image3={spinTheWheel}
               boostRightClass={'customizeGame-right'}
               boostLeftClass={'customizeGame-left'}
               image2Class={'customizeGame-img2'}
               image3Class={''}
           />
           <BoostSectionDivCard
               heading={'WEB & IN APP GAME CAMPAIGNS'}
               content={'Increase user retention by in app and web engagement '}
               image1={playGameBlue}
               image2={playGameGreen}
               image3={''}
               boostRightClass={'webGameCampaign-right'}
               boostLeftClass={'webGameCampaign-left'}
               image2Class={'webGameCampaign-img2'}
               image3Class={'webGameCampaign-img3'}
           />
           <BoostSectionDivCard
               heading={'SUPERCHARGE YOUR GROWTH'}
               content={'Analyse Insights & Capture the market'}
               image1={graphChart}
               image2={''}
               image3={''}
               boostRightClass={'superchargeGrowth-right'}
               boostLeftClass={'superchargeGrowth-left'}
               image2Class={'superchargeGrowth-img2'}
               image3Class={'superchargeGrowth-img3'}
           />
        </div>
    )
}