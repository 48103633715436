import React from 'react';
import {Route, RouterProvider, Routes,} from "react-router-dom";
import {ROUTES} from "./lib/consts";
import LandingPage from "./pages/landingPage/LandingPage";
import Dashboard from "./pages/dashbordPage/Dashboard";
import router from "./routes";
import {Campaign} from "@mui/icons-material";
import CampaignsPage from "./pages/dashbordPage/sections/campaignsSection/CampaignsPage";

function App() {
  return (
    <div>

        <RouterProvider router={router} />
        {/*<Routes>*/}
        {/*    <Route path={ROUTES.HOME_PAGE} element={<LandingPage/>}/>*/}
        {/*</Routes>*/}
        {/*<Dashboard/>*/}
        {/*<CampaignsPage/>*/}
    </div>
  );
}

export default App;
