import {MoreHoriz,} from "@mui/icons-material";
import AddIcon from '@mui/icons-material/Add';
import GamesIcon from '@mui/icons-material/Games';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import MenuItem from "./MenuItem/MenuItem";
import {SvgIconProps} from "@mui/material";
import React, {useState} from "react";
import "./SideMenu.scss";
import Logo from "../../assets/landingPage/engageUsers/logo.png";
import {useLocation, useNavigate} from "react-router-dom";
import {ROUTES} from "../../lib/consts";
// import { logoutUser } from "../../utils/helpers";
// import { useSelector } from "react-redux";
// import { RootState } from "../../store/store";
// import useUserInfo from "../../hooks/useUserInfo";

type MenuItemProp = {
    icon: React.ReactElement<SvgIconProps>;
    title: string;
    route: string | null;
    onClick?: () => void;
};

const SideMenu = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    // const [activeItem, setActiveItem] = useState("Campaign");
    const {pathname} = useLocation();
    const navigate = useNavigate();
    // const { isAdmin, hasSelectedCampaign, campaignKey, reportsList } =
    //   useUserInfo();

    const mainMenu: MenuItemProp[] = [

        {
            title: "Campaign",
            route: ROUTES.CAMPAIGN,
            icon: <AddIcon/>,
        },
        {
            title: "Game Library",
            route: ROUTES.GAME_LIBRARY,
            icon: <GamesIcon/>,
        },
        {
            title: "Campaigns",
            route: ROUTES.CAMPAIGNS,
            icon: <RocketLaunchIcon/>,
        },
        {
            title: "Reports",
            route: ROUTES.REPORTS,
            icon: <DonutSmallIcon/>,
        },
        {
            title: "Settings",
            route: ROUTES.SETTING,
            icon: <ManageAccountsOutlinedIcon/>,
        },
    ];

    // ...(hasSelectedCampaign
    //   ? [
    //       {
    //         title: "Analytics",
    //         route: ROUTES.ANALYTICS(campaignKey || ""),
    //         icon: <Analytics />,
    //       },
    //     ]
    //   : []),
    // ...(reportsList
    //   ? reportsList.map((item) => {
    //       return {
    //         title: item.title,
    //         route: ROUTES.REPORT(item.key),
    //         icon: <TableChart />,
    //       };
    //     })
    //   : []),


    // const footerMenu: MenuItemProp[] = [
    //   ...(isAdmin && hasSelectedCampaign
    //     ? [
    //         {
    //           title: "Data Processing",
    //           route: ROUTES.DATA_PROCESSING,
    //           icon: <SettingsSharp />,
    //         },
    //       ]
    //     : []),
    //   {
    //     title: "Logout",
    //     route: null,
    //     icon: <Logout />,
    //     onClick: logoutUser,
    //   },
    // ];

    return (
        <div
            className={`side-menu ${isExpanded ? "expanded" : ""}`}

            onMouseLeave={() => {
                setIsExpanded(false);
            }}
        >
            <div className="head">
                {isExpanded ? (
                    <img src={Logo} alt="Logo" className="logo"/>
                ) : (
                    <MoreHoriz fontSize="large" onClick={() => setIsExpanded(true)}/>
                )}
            </div>
            <div className="content">
                {mainMenu.map((item) => (

                    <MenuItem
                        key={`main-menu-${item.title}`}
                        title={item.title}
                        icon={item.icon}
                        isActive={pathname === item.route}
                        isExpanded={isExpanded}
                        onClick={() => {
                            if (item.route) navigate(item.route);
                        }}
                    />
                ))}
            </div>
            {/*<div className="footer">*/}
            {/*  {footerMenu.map((item) => (*/}
            {/*    <MenuItem*/}
            {/*      key={`foot-menu-${item.title}`}*/}
            {/*      title={item.title}*/}
            {/*      icon={item.icon}*/}
            {/*      isActive={pathname === item.route}*/}
            {/*      isExpanded={isExpanded}*/}
            {/*      onClick={() => {*/}
            {/*        if (item.route) {*/}
            {/*          navigate(item.route);*/}
            {/*        } else if (item.onClick) {*/}
            {/*          item.onClick();*/}
            {/*        }*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  ))}*/}
            {/*</div>*/}
        </div>
    );
};

export default SideMenu;
