import './style.scss'
import {campaignsCardConst} from "../../../../components/campaignsCard/campaignsCardConst";
import CampaignsCard from "../../../../components/campaignsCard/CampaignsCard";
import Label from "../../../../components/label/Label";
import AddCampaignsCard from "../../../../components/addCampaignsCard/AddCampaignsCard";


export default function CampaignsPage() {

    return (
        <div className={'campaignsPage'}>
            <div className={'heading-wrapper'}>
                <div className={'campaignsPage-heading'}>
                    <h1>Campaigns</h1>
                </div>
                <div className={'campaignsPage-label'}>
                    <Label name={'Live'}/>
                    <Label name={'Completed'}/>
                    <Label name={'Scheduled'}/>
                    <Label name={'Stopped'}/>
                    <Label name={'Drafts'}/>
                </div>
            </div>
            <div className={'campaignsPageCard-wrapper'}>
                <AddCampaignsCard/>
                {
                    campaignsCardConst.map(({campaignName, campaignDate, img, heading}) => (
                        <CampaignsCard img={img} heading={heading} campaignName={campaignName}
                                       campaignDate={campaignDate}/>
                    ))
                }
            </div>
        </div>
    )
}