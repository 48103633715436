import './style.scss'
import {security} from "../../assets/landingPageAssets";


export default function Security() {

    return (
        <div className={'security-section'}>
            <section className={'security-content'}>
                <section className={'security-image'}>
                    <img src={security} alt={'security'}/>
                </section>
                <section className={'section-heading'}>
                    <h1>Security is no joke to us</h1>
                </section>
            </section>

        </div>
    )
}