import './style.scss'
import arrowRight from '../../../../assets/landingPage/engageUsers/arrow_right.svg'

type inputField ={
    inputFieldClass:string,
    inputLeftClass:string,
    inputRightClass:string

}

export default function InputField({inputFieldClass, inputLeftClass, inputRightClass}:inputField) {


    return (
        <div className={inputFieldClass}>
            <section className={inputLeftClass}>
 <input type={'email'} name={'email'} autoComplete={'email'} placeholder={'Enter Email'}/>
            </section>
            <section className={inputRightClass}>
                <button type={'submit'}>
                    <img src={arrowRight} alt={'arrowRight'}/>
                </button>
            </section>
        </div>
    )
}


