import './style.scss'
import EngageUsers from "./section/engageUsersSection/EngageUsers";
import BoostYourBrand from "./section/boostYourBrand/BoostYourBrand";
import HarnessThePower from "./section/harnessThePowerSection/HarnessThePower";
import LadderToUnlock from "./section/ladderToUnlockSection/LadderToUnlock";
import ReachYourNorth from "./section/reachYourNorthSection/ReachYourNorth";
import Security from "./section/securitySection/Security";
import Footer from "./section/footer/Footer";
import UniqueUsers from "./section/uniqueUsersSection/UniqueUsers";


export default function LandingPage() {

    return(
        <div className={'landing-page'}>
            <EngageUsers/>
            <BoostYourBrand/>
            <HarnessThePower/>
            <UniqueUsers/>
            <LadderToUnlock/>
            <ReachYourNorth/>
            <Security/>
            <Footer/>
        </div>
    )
}