import './style.scss'
import AddIcon from '@mui/icons-material/Add';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import {useState} from "react";
import Label from "../label/Label";




export default function AddCampaignsCard() {
const [modal,isModal] = useState(false);

    const toggleModal = () => {
     isModal(!modal);
    }

    if(modal) {
        document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }

    return(
        <div className={'addCampaigns'}>

                   <button onClick={toggleModal} type="button"><AddIcon/></button>

            {modal && (
                <div className="modal">
                    <div onClick={toggleModal} className="overlay"></div>
                    <div className="modal-content">
                        <h2>Create Campaign</h2>
                        <label htmlFor={'Campaign Name'} >Campaign Name</label>
                        <input type="text"name={'Campaign Name'}/>
                        <label htmlFor={'Description'} >Description</label>
                        <input type="text"name={'Description'}/>
                        <h3>Campaign Type</h3>
                        <section className={'modal-content-label'}>
                            <Label name={'Web'}/>
                            <Label name={'In App'}/>
                        </section>
                        <button className="close-modal" onClick={toggleModal}>
                            <ClearOutlinedIcon/>
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

