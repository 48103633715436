import spin_the_Wheel_small from '../../assets/dashbord/spin_the_Wheel_small.png'

export {spin_the_Wheel_small}


export const campaignsCardConst = [
    {
        img: spin_the_Wheel_small,
        heading: "Spin the wheel",
        campaignName: "August Campaign",
        campaignDate: "29-07-2023",
    },
    {
        img: spin_the_Wheel_small,
        heading: "Spin the wheel",
        campaignName: "August Campaign",
        campaignDate: "29-07-2023",
    },
    {
        img: spin_the_Wheel_small,
        heading: "Spin the wheel",
        campaignName: "August Campaign",
        campaignDate: "29-07-2023",
    },
    {
        img: spin_the_Wheel_small,
        heading: "Spin the wheel",
        campaignName: "August Campaign",
        campaignDate: "29-07-2023",
    },
    {
        img: spin_the_Wheel_small,
        heading: "Spin the wheel",
        campaignName: "August Campaign",
        campaignDate: "29-07-2023",
    },
    {
        img: spin_the_Wheel_small,
        heading: "Spin the wheel",
        campaignName: "August Campaign",
        campaignDate: "29-07-2023",
    },
]

