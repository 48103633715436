import './style.scss'

interface labelProps {
    name: string
}


export default function Label(props: labelProps) {

    return(
        <div className={'label'}>
            <p>{props.name}</p>
        </div>
    )
}