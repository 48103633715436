import './style.scss'
import NavBar from '../../component/navbar/NavBar';
import InputField from "../../component/inputField/inputField";
import logoH from '../../../../assets/landingPage/engageUsers/logo-h.svg'
import { useState} from "react";
import {MuiDrawer} from "../../component/drawer/Drawer";


export default function EngageUsers() {

    const [show, setshow] = useState(false);



    return (
        <div className={'heroSection'}>
            <NavBar showDrawer={setshow}/>
            {show ? <MuiDrawer showDrawer={setshow}/> : <span></span>}
            <section className={'heroContent'}>
                <h1>Engage Users &<br/> Boost Brand Presence</h1>
                <p>PlayVerra is a platform for automated customisable gaming campaign as per your brand requirement.
                    You have complete control & visibility of campaigns,</p>
            </section>
            <InputField inputFieldClass={'heroinputField'} inputLeftClass={'heroinput-left'}
                        inputRightClass={'heroinput-right'}/>
            <section className={'logo-div'}>
                <img src={logoH} alt={'logoH'}/>
            </section>

        </div>
    )
}