import './style.scss'
import SideMenu from "../../components/SideMenu/SideMenu";
import { Outlet } from "react-router-dom";


export default function Dashboard() {


    return (
        <div className={'dashboard-layout'}>
            <section className={'dashboard-layout-sidebar'}>
                <SideMenu/>
            </section>
            <section className={'dashboard-layout-card'}>
                <Outlet />
            </section>

        </div>
    )
}