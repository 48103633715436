import { createBrowserRouter } from "react-router-dom";
import { ROUTES } from "../lib/consts";
import GameCardSection from "../pages/dashbordPage/sections/gameCardSection/GameCardSection";
import Dashboard from "../pages/dashbordPage/Dashboard";
import LandingPage from "../pages/landingPage/LandingPage";
import CampaignsPage from "../pages/dashbordPage/sections/campaignsSection/CampaignsPage";
import AppPrivacyPolicy from "../pages/playverra-app/PrivacyPolicy/PrivacyPolicy";
import AppTandc from "../pages/playverra-app/Tandc/Tandc";

const router = createBrowserRouter(
  [
    {
      path: ROUTES.LANDING_PAGE,
      element: <LandingPage />,
    },
    {
      path: ROUTES.DASHBOARD,
      element: <Dashboard />,
      children: [
        {
          index: true,
          element: <GameCardSection />,
        },
        {
          path: ROUTES.CAMPAIGN,
          element: <GameCardSection />,
        },
        {
          path: ROUTES.GAME_LIBRARY,
          element: <GameCardSection />,
        },
        {
          path: ROUTES.CAMPAIGNS,
          element: <CampaignsPage />,
        },
        {
          path: ROUTES.REPORTS,
          element: <GameCardSection />,
        },
        {
          path: ROUTES.SETTING,
          element: <GameCardSection />,
        },
      ],
    },
    {
      path: ROUTES.APP_PRIVACY_POLICY,
      element: <AppPrivacyPolicy />,
    },
    {
      path: ROUTES.APP_TANDC,
      element: <AppTandc />,
    },
    {
      path: "*",
      element: <GameCardSection />,
    },
  ],
  { basename: process.env.REACT_APP_BASE_URL }
);

export default router;
