import React from 'react'
import './style.scss'

interface BrandLogoLoopProps  {
    labels: string[]
}

const LabelLoop: React.FC<BrandLogoLoopProps> = ({labels}) => {
    return (
        <>
            {labels.map((label, index) => {
                return <div className={'cardLabel'}><p>{label}</p></div>
            })}
        </>
    );
}
export default LabelLoop;