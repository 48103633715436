import './style.scss'
import {Box, Drawer} from '@mui/material'
import {useState} from 'react'
import CloseIcon from '@mui/icons-material/Close';
import {fb, google} from "../../assets/landingPageAssets";
import {Link} from "react-router-dom";

const bp = {
    drawerWidth: {
        width: '40%',
        '@media (max-width: 768px)': {
            width: '80%'
        }
    }
}
export const MuiDrawer = (props: any) => {



    return (
        <>

            <Drawer
                anchor='right'
                color='inherit'
                open={true}
            >
                <CloseIcon onClick={() => props.showDrawer(false)}
                           style={{position: 'relative', top: '3vh', left: '4.5vw', fontSize: '35px'}}/>
                <Box p={2} width={{xs: '92vw', sm: '92vw', md: '92vw', lg: '40vw'}} role='presentation'
                     style={{display: 'flex', justifyContent: 'center '}}>
                    <div className={'login-div'}>
                        <h1>Login</h1>
                        <section className={'field-section'}>
                            <label htmlFor={'email'}>Email address</label>
                            <input type="email" name="email"/>
                            <label htmlFor={'Password'}>Password</label>
                            <input type="password" name="password"/>
                            <div className={'field-bottom-section'}>
                                <div className={'field-bottom-left'}>
                                    <input type={'checkbox'} name={'checkbox'}/>
                                    <label htmlFor={'checkbox'} className={'remember-me'}>Remember Me</label>
                                </div>
                                <div className={'field-bottom-right'}>
                                    <p>Forgot your password?</p>
                                </div>
                            </div>
                            <Link to={'/dashboard'}>
                                <button type="submit" name="login">Login</button>
                            </Link>
                        </section>

                        <section className={'o-login-section'}>
                            <div/>
                            <p>Or Login with</p>
                            <div/>
                        </section>

                        <section className={'logo-section'}>
                            <img src={fb} alt={'facebook'}/>
                            <img src={google} alt={'google'}/>
                        </section>

                    </div>
                </Box>
            </Drawer>
        </>
    )
}


