import './style.scss'
import GameLibraryCard from "../../../../components/gameLibraryCard/GameLibraryCard";
import {cardConst} from "../../../../components/gameLibraryCard/cardConst";


export default function GameCardSection() {


    return (
        <div className={'cardTest'}>
            <div className={'cardTest-heading'}>
               <h1>Game Library</h1>
            </div>
            {
                cardConst.map(({
                                   heading, secondHeading, label, numbers, image, brand
                               }) => (

                    <GameLibraryCard src={image} heading={heading} secondHeading={secondHeading} label={label}
                                     brandCount={numbers} logo={brand} key={heading}/>

                ))
            }
        </div>
    )
}