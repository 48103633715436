export const ROUTES = {
  LANDING_PAGE: "/",
  LOGIN: "/login",
  DASHBOARD: "/dashboard",
  CAMPAIGNS: "/dashboard/campaigns",
  GAME_LIBRARY: "/dashboard/game_library",
  CAMPAIGN: "/dashboard/campaign",
  REPORTS: "/dashboard/reports",
  SETTING: "/dashboard/setting",
  APP_PRIVACY_POLICY: "/playverra-app/privacy-policy",
  APP_TANDC: "/playverra-app/tandc",
};
