import './style.scss'


export default function UniqueUsers() {

    return (
        <div className={'uniqueUsers'}>
            <section>
                <h1>200+</h1>
                <p>Campaigns</p>
            </section>
            <section>
                <h1>75 M+</h1>
                <p>Views</p>
            </section>
            <section>
                <h1>10 M+</h1>
                <p>Unique Users</p>
            </section>
            <section>
                <h1>30 %</h1>
                <p>Increase in conversions</p>
            </section>

        </div>
    )
}